// Variables
@import "../../assets/css/vars";

.month-videos {
    background-color: #F6F6F6;
    padding: 66px 0;

    .videos-list {
        background-color: $white;
        border-radius: 7px;
        box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
        overflow-y: auto;
        overflow-x: hidden;
        max-height: 560px;

        .video-item {
            min-height: 61px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 16px 30px;
            cursor: pointer;

            h3 {
                font: $small-size-bold;
                color: $dark-blue-two;
                padding-left: 15px;
            }

            i {
                color: $dark-blue-two;
                font-size: 18px;
            }

            &:hover,
            &.active {
                background-color: $light-blue;

                i {
                    color: $blue;
                }
            }
        }

        &::-webkit-scrollbar {
            width: 10px;
        }

        &::-webkit-scrollbar-track {
            width: 10px;
            background-color: #e4e4e4;
        }

        &::-webkit-scrollbar-thumb {
            background-color: #bbbbbb;
            border-radius: 10px;
        }
    }
}

.video-details {
    .video-react {
        margin-bottom: 20px;
        border-radius: 7px;
        overflow: hidden;
        box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
    }

    h2 {
        color: $dark-blue;
        font: $large-size-black;
        margin-bottom: 14px;
    }

    .description {
        font: $small-size-sbold;
        color: $dark-blue-two;
    }

    .attachments {
        margin-top: 32px;
        box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
        border-radius: 7px;
        overflow: hidden;

        .title {
            display: flex;
            align-items: center;
            justify-content: space-between;
            font: $small-size-bold;
            color: $dark-blue-two;
            width: 100%;
            background-color: $white;
            padding: 16px 20px;
            cursor: pointer;
        }
        
        .files {
            background-color: $white;
            padding: 16px 20px;
            display: none;
            
            .single-file {
                a {
                    color: $dark-blue-one;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    font: $very-small-size-bold;
                    margin-bottom: 16px;
                }

                &:last-of-type {
                    a {
                        margin-bottom: 0;
                    }
                }
            }

            &.active {
                display: block;
            }
        }
    }
}