// Colors
$blue: #ea3838;
$dark-blue: #b33131;
$yellow: #eeb807;
$light-blue: #f5e3e3;
$dark-blue-one: #823232;
$dark-blue-two: #884343;
$white: #fff;
$overlay-layer: rgba(234, 56, 56, 0.6);
$warning: rgba(175, 46, 46, 0.32);
$warningTxt: #af2e2e;
$success: rgba(46, 173, 84, 0.32);
$successTxt: #2eaf55;

// Fonts
$font: 'Cairo';
$small-size-reg: 100 16px #{$font};
$small-size-sbold: 200 16px #{$font};
$small-size-bold: 300 16px #{$font};
$small-size-black: 400 16px #{$font};

$very-small-size-reg: 100 14px #{$font};
$very-small-size-sbold: 200 14px #{$font};
$very-small-size-bold: 300 14px #{$font};
$very-small-size-black: 400 14px #{$font};

$med-size-reg: 100 20px #{$font};
$med-size-sbold: 200 20px #{$font};
$med-size-bold: 300 20px #{$font};
$med-size-black: 400 20px #{$font};

$large-size-reg: 100 25px #{$font};
$large-size-sbold: 200 25px #{$font};
$large-size-bold: 300 25px #{$font};
$large-size-black: 400 25px #{$font};

$half-big-size-reg: 100 30px #{$font};
$half-big-size-sbold: 200 30px #{$font};
$half-big-size-bold: 300 30px #{$font};
$half-big-size-black: 400 30px #{$font};

$big-size-reg: 100 35px #{$font};
$big-size-sbold: 200 35px #{$font};
$big-size-bold: 300 35px #{$font};
$big-size-black: 400 35px #{$font};