// Variables
@import "../../assets/css/vars";

.sign-up-page {
    padding: 30px 0;

    .note {
        text-align: center;

        p {
            color: #848484;
            display: inline-block;
            font: $small-size-sbold;
            position: relative;
            margin-bottom: 40px;

            span {
                position: absolute;
                left: -15px;
                color: #E14545;
                font: 300 25px Cairo;
                top: -7px;
            }
        }
    }

    .registeration-form {
        margin-bottom: 50px;

        .col-md-6 {
            margin-bottom: 35px;
        }

        .field {

            label {
                color: $dark-blue;
                font: $med-size-black;
                margin-bottom: 5px;
            }

            input,
            select {
                outline-color: $blue;
                width: 100%;
                height: 60px;
                border-radius: 7px;
                border: 1px solid $dark-blue-two;
                padding: 5px 20px;
                font: $small-size-bold;
                color: $dark-blue-two;

                &::placeholder {
                    color: $dark-blue-two;
                }
            }
        }

        .message {
            padding: 18px 0px;
            border-radius: 7px;
            text-align: center;
            font: $small-size-bold;
        }

        .message.warning {
            background-color: $warning;
            color: $warningTxt;
        }
       
        .message.success {
            background-color: $success;
            color: $successTxt;
        }

        button {
            border: none;
            width: 100%;
            border-radius: 7px;
            height: 60px;
            background-color: $blue;
            color: #fff;
            font: $small-size-bold;
            
            &.loading {
                background-color: $dark-blue-two;
            }
        }
    }
}