// Variables
@import "../../assets/css/vars";

footer {
    img {
        max-width: 100%;
        max-height: 192px;
    }

    .info {
        padding: 50px;
        background-color: $dark-blue;

        .contact {
            text-align: left;

            a {
                color: $white;
                font: $big-size-sbold;
                display: block;
                margin-bottom: 5px;
            }
        }

        .social-media {
            margin-top: 30px;

            a {
                color: $white;
                background-color: $dark-blue-one;
                width: 60px;
                height: 60px;
                display: inline-flex;
                align-items: center;
                justify-content: center;
                border-radius: 50%;
                font-size: 30px;
                margin: 0 7px;
                transition: all 0.2s;

                &:hover {
                    background-color: $light-blue;
                    color: $blue;
                }
            }
        }
    }

    .copywrights {
        padding: 30px 0;
        background-color: $dark-blue-one;
        text-align: center;

        span {
            color: $white;
            font: $med-size-reg;
        }
    }
}